<template>
  <b-card no-body>
    <div class="table-card-header">
      <!-- Tabs -->
      <b-tabs
        v-if="tabOptions.length"
        nav-class="border-bottom mb-0"
      >
        <!-- Add your b-tab components here -->
        <template #tabs-start>
          <b-nav-item
            v-for="(item, index) in tabOptions"
            :key="`tab-item-${index}`"
            role="presentation"
            :active="_.some(filter, { label: item.label })"
            @click="selectFilter(item)"
          >
            {{ item.label }}
            <b-badge
              v-if="item.tag"
              variant="primary"
              class="ml-50"
              pill
            >
              <span v-if="typeof item.tag === 'number' && item.tag > 999">999+</span>
              <span v-else>{{ item.tag }}</span>
            </b-badge>
          </b-nav-item>
        </template>
      </b-tabs>

      <!-- Header -->
      <b-card-header class="border-bottom">
        <b-form-row class="w-fill-available">
          <!-- Search bar -->
          <b-col
            :cols="hasActionCreate ? 7 : 12"
            md
            class="pb-50 pb-md-0"
            order="1"
            order-md="1"
          >
            <b-form-row>
              <b-col md="5">
                <b-input-group class="input-group-merge position-relative">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <!-- Search field -->
                  <b-form-input
                    v-model="defaultKeyword"
                    :placeholder="searchPlaceholder"
                    @input="search"
                  />
                  <!-- Reset keyword -->
                  <b-input-group-append
                    v-if="defaultKeyword"
                    is-text
                    class="cursor-pointer"
                    @click="resetKeyword"
                  >
                    <feather-icon
                      icon="XIcon"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-form-row>
          </b-col>

          <!-- Sort -->
          <b-col
            cols="6"
            md="auto"
            order="4"
            order-md="3"
          >
            <b-dropdown
              variant="outline-dark"
              class="w-100"
              toggle-class="pl-1 border-default"
              right
            >
              <!-- Content -->
              <template #button-content>
                <feather-icon
                  icon="CornerRightDownIcon"
                  class="mr-50"
                />
                <!-- find matching sort inside the options (if any) -->
                Sort<span v-if="defaultSort && defaultSort.key">: {{ defaultSort.label }}</span>
              </template>
              <!-- None -->
              <b-dropdown-item @click="resetSort">
                <feather-icon
                  v-if="!defaultSort"
                  icon="CheckIcon"
                  class="mr-1"
                />
                <span :class="{'ml-2 pl-50': defaultSort}">None</span>
              </b-dropdown-item>
              <!-- Options -->
              <b-dropdown-item
                v-for="(option, index) in sortOptions"
                :key="`sort-option-${index}`"
                @click="changeSort(option)"
              >
                <feather-icon
                  v-if="defaultSort && _.isMatch(option, defaultSort)"
                  icon="CheckIcon"
                  class="mr-1"
                />
                <span :class="{'ml-2 pl-50': !_.isMatch(option, defaultSort) || !defaultSort}">{{ option.label }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-col>

          <!-- Create action -->
          <b-col
            v-if="hasActionCreate"
            cols="5"
            md="auto"
            order="2"
            order-md="4"
          >
            <b-button
              block
              variant="primary"
              :to="`${$route.path}/create`"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Create <span class="d-none d-md-inline-block">{{ label }}</span></span>
            </b-button>
          </b-col>

          <!-- Download action -->
          <b-col
            v-for="(item, index) in defaultCustomActions"
            :key="`custom-action-${index}`"
            cols="5"
            md="auto"
            order="2"
            order-md="4"
          >
            <b-button
              block
              variant="primary"
              class="pl-1"
              :class="{'pl-1': item.icon}"
              @click="item.event"
            >
              <feather-icon
                v-if="item.icon"
                :icon="item.icon"
                class="mr-75"
              />
              <span>{{ item.label }}</span>
            </b-button>
          </b-col>

          <!-- Download action -->
          <b-col
            v-if="hasActionDownload"
            cols="5"
            md="auto"
            order="2"
            order-md="4"
          >
            <b-button
              block
              variant="primary"
              class="pl-1"
              @click="requestExport"
            >
              <feather-icon
                icon="UploadIcon"
                class="mr-75"
              />
              <span>Export data</span>
            </b-button>
          </b-col>
        </b-form-row>

        <!-- Filter -->
        <b-form-row v-if="hasFilter || hasToggleableField">
          <!-- Multiple row actions -->
          <b-col
            v-if="showMultipleRowActions"
            cols="auto"
            class="mt-1"
            :class="{ 'pr-1': hasFilter || hasToggleableField }"
          >
            <b-dropdown
              size="sm"
              variant="primary"
              no-flip
            >
              <template #button-content>
                {{ selectedIds.length }} selected
              </template>
              <!-- action menus -->
              <b-dropdown-item
                v-for="(item, index) in defaultMultipleRowActions"
                :key="`multiple-row-action-${index}`"
                @click="item.multipleRowEvent"
              >
                {{ item.label }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>

          <!-- Filters -->
          <b-col
            v-for="(item, index) in defaultFilterOptionsCollapsible"
            :key="`custom-filter-${index}`"
            cols="auto"
            class="mt-1"
            :class="{ 'pl-1 border-left': showMultipleRowActions && index === 0 }"
          >
            <!-- Profile filter -->
            <b-dropdown
              v-if="item.filter.type === 'profile'"
              size="sm"
              variant="outline-dark"
              class="b-dropdown-compact"
              menu-class="min-w-180px"
              :toggle-class="{
                'border-default': !getFilterIsActive(item),
                'border-primary': getFilterIsActive(item)
              }"
              @hidden="searchFilter = ''"
              @show="() => openProfileDropdown(item)"
            >
              <!-- Content -->
              <template #button-content>
                <span class="text-secondary mr-50">{{ item.label }}</span>
                <!-- Value(s) -->
                <span
                  v-if="getFilterIsActive(item)"
                  class="font-weight-bold"
                >{{ _.find(filter, { key: item.filter.key ? item.filter.key : item.key }).text }}</span>
                <!-- Default value -->
                <span
                  v-else
                  class="font-weight-bold"
                >All</span>
              </template>
              <!-- Search -->
              <b-dropdown-form class="px-50 pb-50">
                <b-form-input
                  v-model="searchFilter"
                  placeholder="Search"
                  @input="keyword => searchProfile(keyword, item)"
                />
              </b-dropdown-form>
              <b-dropdown-item
                v-if="!searchFilter"
                @click="resetFilter(item.filter.key)"
              >
                All
              </b-dropdown-item>

              <b-dropdown-item
                v-if="_.find(filter, { key: item.filter.key ? item.filter.key : item.key }) && !_.get($store.state, item.filter.stateOptions).length"
                active
                @click="resetFilter(item.filter.key)"
              >
                {{ _.find(filter, { key: item.filter.key ? item.filter.key : item.key }).text }}
              </b-dropdown-item>

              <b-dropdown-item
                v-for="(option, optionIndex) in _.get($store.state, item.filter.stateOptions).slice(0, 5)"
                :key="`custom-filter-${optionIndex}`"
                :active="_.find(filter, { value: option.value }) !== undefined"
                @click="toggleSelectFilter(item.filter.key ? item.filter.key : item.key, option)"
              >{{ option.text }}</b-dropdown-item>

              <b-dropdown-item
                v-if="!searchFilter || _.get($store.state, item.filter.stateOptions).length > 5"
                disabled
              >
                <feather-icon
                  icon="MoreHorizontalIcon"
                  class="pointer-events-none"
                  size="16"
                />
              </b-dropdown-item>
            </b-dropdown>

            <!-- Single value -->
            <b-dropdown
              v-if="item.filter.type === 'select'"
              size="sm"
              variant="outline-dark"
              class="b-dropdown-compact"
              :toggle-class="{
                'border-default': !getFilterIsActive(item),
                'border-primary': getFilterIsActive(item)
              }"
              @hidden="searchFilter = ''"
            >
              <!-- Content -->
              <template #button-content>
                <span class="text-secondary mr-50">{{ item.label }}</span>
                <!-- Value(s) -->
                <span
                  v-if="getFilterIsActive(item)"
                  class="font-weight-bold"
                >{{ _.find(filter, { key: item.filter.key ? item.filter.key : item.key }).text }}</span>
                <!-- Default value -->
                <span
                  v-else
                  class="font-weight-bold"
                >All</span>
              </template>
              <!-- Search -->
              <b-dropdown-form
                v-if="item.filter.options.length >= 7"
                class="px-50 pb-50"
              >
                <b-form-input
                  v-model="searchFilter"
                  placeholder="Search"
                />
              </b-dropdown-form>
              <b-dropdown-divider v-if="item.filter.options.length >= 7" />
              <!-- Options -->
              <!-- Default -->
              <b-dropdown-item
                v-if="!searchFilter"
                @click="resetFilter(item.filter.key)"
              >
                All
              </b-dropdown-item>
              <!-- Other options -->
              <b-dropdown-item
                v-for="(option, optionIndex) in item.filter.options"
                :key="`custom-filter-${optionIndex}`"
                :hidden="!!searchFilter && !option.text.toLowerCase().includes(searchFilter.toLowerCase())"
                :active="_.find(filter, { value: option.value }) !== undefined"
                @click="toggleSelectFilter(item.filter.key ? item.filter.key : item.key, option)"
              >{{ option.text }}</b-dropdown-item>
            </b-dropdown>

            <!-- Multiple value -->
            <b-dropdown
              v-if="item.filter.type === 'multiple'"
              size="sm"
              variant="outline-dark"
              class="b-dropdown-compact"
              :toggle-class="{
                'border-default': !getFilterIsActive(item),
                'border-primary': getFilterIsActive(item)
              }"
              @hidden="searchFilter = ''"
            >
              <!-- Content -->
              <template #button-content>
                <span class="text-secondary mr-50">{{ item.label }}</span>
                <!-- Value(s) -->
                <span
                  v-if="getFilterIsActive(item)"
                  class="font-weight-bold"
                >{{ _.find(filter, { key: item.filter.key ? item.filter.key : item.key }).value
                  .map(value => item.filter.options.find(option => option.value === value).text)
                  .join(', ') }}</span>
                <!-- Default value -->
                <span
                  v-else
                  class="font-weight-bold"
                >All</span>
              </template>
              <!-- Search -->
              <b-dropdown-form
                v-if="item.filter.options.length >= 7"
                class="px-50 pb-50"
              >
                <b-form-input
                  v-model="searchFilter"
                  placeholder="Search"
                />
              </b-dropdown-form>
              <b-dropdown-divider v-if="item.filter.options.length >= 7" />
              <!-- Options -->
              <!-- Default -->
              <b-dropdown-item
                v-if="!searchFilter"
                @click="resetFilter(item.filter.key)"
              >
                All
              </b-dropdown-item>
              <!-- Other options -->
              <b-dropdown-item
                v-for="(option, optionIndex) in item.filter.options"
                :key="`custom-filter-${optionIndex}`"
                :hidden="!!searchFilter && !option.text.toLowerCase().includes(searchFilter.toLowerCase())"
                :active="(_.filter(filter, { key: item.filter.key ? item.filter.key : item.key, value: [option.value] }) || []).length > 0"
                @click="toggleAddFilter(item.filter.key ? item.filter.key : item.key, option)"
              >{{ option.text }}</b-dropdown-item>
            </b-dropdown>

            <!-- Datetime value -->
            <b-dropdown
              v-if="item.filter.type === 'datetime'"
              size="sm"
              variant="outline-dark"
              :toggle-class="{
                'border-default': !getFilterIsActive(item),
                'border-primary': getFilterIsActive(item)
              }"
            >
              <!-- Content -->
              <template #button-content>
                <span class="text-secondary mr-50">{{ item.label }}</span>
                <!-- Value(s) -->
                <span
                  v-if="getFilterIsActive(item)"
                  class="font-weight-bold"
                >{{ _.find(filter, { key: item.filter.startKey }).text }}</span>
                <!-- Default value -->
                <span
                  v-else
                  class="font-weight-bold"
                >All</span>
              </template>
              <!-- Options -->
              <!-- Default -->
              <b-dropdown-item @click="resetDatetimeFilter(item.filter.startKey, item.filter.endKey)">
                All
              </b-dropdown-item>
              <!-- Other options -->
              <b-dropdown-item
                v-for="(option, optionIndex) in datetimeOptions"
                :key="`custom-filter-${optionIndex}`"
                @click="toggleDatetimeFilter(item.filter.startKey, item.filter.endKey, option)"
              >{{ option.text }}</b-dropdown-item>
              <b-dropdown-divider />
              <!-- Default -->
              <b-dropdown-item @click="openDatePicker(item.filter.startKey, item.filter.endKey)">
                Custom
              </b-dropdown-item>
            </b-dropdown>
          </b-col>

          <!-- Show all filter -->
          <b-col
            v-if="filterCollapsed"
            cols="auto"
          >
            <b-button
              v-b-tooltip.v-dark.top="'Show all'"
              size="sm"
              variant="outline-dark"
              class="border-default mt-1 px-75 d-flex align-items-center"
              @click="filterCollapsedToggle = !filterCollapsedToggle"
            >
              <feather-icon
                icon="CodeIcon"
                size="12"
              />
            </b-button>
          </b-col>

          <!-- Clear all filter -->
          <b-col
            v-if="hasFilter"
            cols="auto"
            :class="{ 'pr-1': hasToggleableField }"
          >
            <b-button
              size="sm"
              variant="outline-dark"
              class="border-default mt-1 pl-50 d-flex align-items-center"
              @click="resetAllFilter"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
                size="12"
              />
              Clear all
            </b-button>
          </b-col>

          <!-- Custom fields visibility -->
          <b-col
            v-if="hasToggleableField"
            cols="auto"
            class="mt-1"
            :class="{ 'pl-1 border-left': hasFilter || showMultipleRowActions }"
          >
            <b-dropdown
              size="sm"
              variant="outline-dark"
              toggle-class="border-default"
              no-flip
            >
              <template #button-content>
                <feather-icon
                  icon="ToggleLeftIcon"
                  class="mr-25"
                  size="12"
                />
                Fields
              </template>
              <b-dropdown-form>
                <div
                  v-for="(item, optionIndex) in toggleableFields"
                  :key="`table-field-${optionIndex}`"
                  @click.prevent="toggleFieldVisibility(item)"
                >
                  <b-form-checkbox
                    :checked="item.visibility === undefined
                      || (item.visibility
                        && (!item.visibility.length
                          || item.visibility.includes('table')))"
                    size="sm"
                    switch
                    inline
                  >
                    {{ item.label }}
                  </b-form-checkbox>
                </div>
              </b-dropdown-form>
              <b-dropdown-divider v-if="hasResetToggleableField" />
              <b-dropdown-item
                v-if="hasResetToggleableField"
                @click="resetToggleableField"
              >
                Reset to default
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-form-row>
      </b-card-header>
    </div>

    <!-- Table -->
    <b-overlay
      :show="loading"
      opacity="0.5"
    >
      <b-table
        :items="items"
        :fields="defaultFields"
        responsive
        show-empty
        selectable
        :busy="loading && !items.length"
        no-local-sorting
        select-mode="single"
        thead-class="fixed-thead"
        thead-tr-class="table-white-space"
        :tbody-tr-class="rowClass"
        @row-selected="onSelect"
        @sort-changed="onSort"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <strong>Loading...</strong>
          </div>
        </template>

        <template #head(select)>
          <b-form-checkbox
            :checked="hasSelectedRow"
            :indeterminate="isIntermediateSelected"
            @change="toggleSelectAllRow"
          />
        </template>

        <template #cell(select)="data">
          <b-form-checkbox
            :checked="selectedIds.includes(data.item.id)"
            @change="toggleSelectRow(data.item.id)"
          />
        </template>

        <template #cell(actions)="data">
          <b-row
            no-gutters
            class="flex-nowrap"
          >
            <!-- View action -->
            <b-col
              v-if="hasActionView"
              cols="auto"
            >
              <b-button
                v-b-tooltip.v-dark.top="{ title: 'View', disabled: hasActionMenuViewOnly }"
                variant="flat-dark"
                class="btn-icon"
                :class="{ 'd-flex align-items-center': hasActionMenuViewOnly }"
                size="sm"
                :to="`#${data.item.id}`"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="pointer-events-none"
                  size="16"
                />
                <!-- Show label if read only -->
                <span
                  v-if="hasActionMenuViewOnly && !getHasActionOther(data.item)"
                  class="ml-50"
                >View</span>
              </b-button>
            </b-col>
            <!-- Update action -->
            <b-col
              v-if="hasActionUpdate"
              cols="auto"
            >
              <b-button
                v-b-tooltip.v-dark.top="'Edit'"
                variant="flat-dark"
                class="btn-icon"
                size="sm"
                :to="`${$route.path}/${data.item.id}/edit`"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="pointer-events-none"
                  size="16"
                />
              </b-button>
            </b-col>
            <!-- Delete action -->
            <b-col
              v-if="hasActionDelete"
              cols="auto"
            >
              <b-button
                v-b-tooltip.v-danger.top="'Delete'"
                variant="flat-dark"
                class="btn-icon"
                size="sm"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="pointer-events-none"
                  size="16"
                />
              </b-button>
            </b-col>
            <!-- Other action -->
            <b-col
              v-if="getHasActionOther(data.item)"
              cols="auto"
            >
              <b-dropdown
                variant="flat-dark"
                right
                class="dropdown-icon-wrapper"
                toggle-class="btn-icon"
                size="sm"
              >
                <!-- Other action icon -->
                <template #button-content>
                  <feather-icon
                    icon="MoreHorizontalIcon"
                    class="pointer-events-none"
                    size="16"
                  />
                </template>
                <!-- Other action menus -->
                <b-dropdown-item
                  v-for="(item, itemIndex) in getActionOther(data.item)"
                  :key="`other-action-${data.item.id}-${itemIndex}`"
                  v-b-tooltip.v-dark.top="item.tooltipText ? item.tooltipText : ''"
                  @click="() => item.event(data)"
                >
                  {{ item.label }}
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
        </template>
        <!-- Optional default data cell scoped slot -->
        <template #cell()="data">
          <TableField
            :data="data.item"
            :value="data.value"
            :prefix="data.field.prefix"
            :suffix="data.field.suffix"
            :type="data.field.type"
            :map="data.field.map"
            :image="_.get(data.item, data.field.image)"
            :image-key="data.field.imageKey"
            :title-key="data.field.titleKey"
            :url="data.field.url"
            :has-zero="data.field.hasFree"
            :has-detail="hasDetail"
          />
        </template>
      </b-table>
    </b-overlay>

    <!-- Pagination and page settings -->
    <div
      v-if="pagination"
      class="px-1"
    >
      <b-form-row class="align-items-center mb-1">
        <!-- Data per page label -->
        <b-col cols="auto">
          Rows per page:
        </b-col>
        <!-- Data per page option -->
        <b-col cols="auto">
          <b-form-select
            :value="perPage"
            :options="perPageOptions"
            @input="changePerPage"
          />
        </b-col>
        <!-- Data per page label -->
        <b-col
          v-if="!loading || items.length"
          cols="auto"
          class="mx-1"
        >
          {{ startRowNumber | number }}
          -
          {{ endRowNumber | number }}
          of
          {{ pagination.totalData | number }}
        </b-col>
        <!-- Pagination -->
        <b-col>
          <b-pagination
            align="right"
            class="mb-0"
            :value="pagination.currentPage"
            :per-page="pagination.perPage"
            :total-rows="pagination.totalData"
            :disabled="loading"
            @page-click="changePage"
          />
        </b-col>
        <!-- Pagination input -->
        <b-col
          v-if="pagination.totalData > endRowNumber"
          cols="auto"
        >
          or
        </b-col>
        <!-- Pagination input -->
        <b-col
          v-if="pagination.totalData > endRowNumber"
          cols="auto"
        >
          <b-form-input
            class="w-50px"
            :value="pagination.currentPage"
            type="number"
            @input="onChangePage"
          />
        </b-col>
      </b-form-row>
    </div>

    <!-- View data modal -->
    <!-- do not use v-model, causing wrong UI behavior -->
    <b-modal
      :visible="viewDataModalVisible"
      title="View detail"
      ok-title="Done"
      ok-only
      @hidden="viewDataModalVisible = false"
    >
      <FormView
        :data="defaultDetail"
        :fields="fields"
        lazy
        :has-detail="hasDetail"
      />
    </b-modal>

    <!-- Custom date picker modal -->
    <!-- do not use v-model, causing wrong UI behavior -->
    <b-modal
      :visible="datePickerModalVisible"
      dialog-class="w-fit-content"
      title="Select date"
      ok-title="Apply"
      cancel-title="Cancel"
      cancel-variant="light"
      body-class="p-50"
      @hidden="onDatePickerModalHidden"
      @ok="selectDateRange"
    >
      <b-form-group class="flatpickr-form-group shadow-none">
        <flat-pickr
          v-model="dateRange"
          class="form-control d-none"
          :config="{ mode: 'range', inline: true, disable:[{from: new Date(), to:'2099-02-21'}] }"
        />
      </b-form-group>
    </b-modal>

    <!-- no datetime filter applied alert -->
    <b-modal
      v-if="hasActionDownload"
      :visible="confirmExportModalVisible"
      title="Export"
      ok-title="Yes, sure"
      cancel-title="Cancel"
      cancel-variant="light"
      @hidden="onAlertExportModalHidden"
      @ok="exportData"
    >
      Are you sure want to export the data?. <span v-if="pagination && pagination.totalData > 1000">You may only be allowed to get a maximum of 1000 records.</span>
    </b-modal>

    <!-- no datetime filter applied alert -->
    <b-modal
      v-if="hasActionDownload"
      :visible="alertExportModalVisible"
      title="Apply Date Range"
      :ok-title="`Ok, got it`"
      ok-only
      @hidden="onAlertExportModalHidden"
    >
      Please provide desired date range to proceed with the export.
    </b-modal>
  </b-card>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'
import TableField from '@/layouts/components/TableField.vue'
// import locally because this._.debounce is always undefined
import debounce from 'lodash/debounce'
import moment from 'moment'
// import { updatedDiff } from 'deep-object-diff'
import flatPickr from 'vue-flatpickr-component'

import {
  BRow,
  BFormRow,
  BCol,
  BCard,
  BCardHeader,
  BTable,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BModal,
  BButton,
  BTabs,
  BPagination,
  BNavItem,
  BOverlay,
  BFormSelect,
  BFormCheckbox,
  BDropdownForm,
  BFormGroup,
  BBadge,
} from 'bootstrap-vue'

export default {
  metaInfo() {
    return {
      title: this.titleKey && this.defaultDetail && this.viewDataModalVisible ? this._.get(this.defaultDetail, this.titleKey) : undefined,
      titleTemplate: this.titleKey && this.defaultDetail && this.viewDataModalVisible ? `${this.$route.meta.pageTitle} - %s` : 'Admin - %s',
    }
  },
  components: {
    FormView,
    BRow,
    BFormRow,
    BCol,
    BTable,
    BCard,
    BCardHeader,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BModal,
    BButton,
    BTabs,
    BNavItem,
    BPagination,
    BFormSelect,
    BOverlay,
    TableField,
    BFormCheckbox,
    BDropdownForm,
    BFormGroup,
    BBadge,
    flatPickr,
  },
  props: {
    titleKey: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: '',
    },
    searchPlaceholder: {
      type: String,
      default: 'Search',
    },
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      required: true,
    },
    sort: {
      type: Object,
      default: () => {},
    },
    sortOptions: {
      type: Array,
      required: true,
    },
    filter: {
      type: Array,
      default: () => {},
    },
    tabOptions: {
      type: Array,
      default: () => [],
    },
    actions: {
      type: Array,
      default: () => [],
    },
    detail: {
      type: Object,
      default: () => {},
    },
    pagination: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    keyword: {
      type: String,
      default: '',
    },
    selectedIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      name: '',
      defaultKeyword: '',
      searchFilter: '',
      filterCollapsedLimit: 5,
      perPage: this.$store.state.appConfig.settings.tablePerPage,
      // Modal
      viewDataModalVisible: false,
      datePickerModalVisible: false,
      alertExportModalVisible: false,
      confirmExportModalVisible: false,
      exportWithoutDateRangeCountDown: 0,
      // Filter option collapse toggle
      filterCollapsedToggle: true,

      dateRange: null,
      dateRangeStartKey: '',
      dateRangeEndKey: '',

      // Per page options
      perPageOptions: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        { value: 100, text: 100 },
      ],

      // Datetime options
      // use 'minuteValue' instead of 'value'
      // because the 'value' will be in ISO datetime format
      datetimeOptions: [
        { minuteValue: 0, text: 'Today' },
        { minuteValue: 1, text: 'Yesterday' },
        { minuteValue: 60, text: 'Last 1 hour' },
        { minuteValue: 1440, text: 'Last 24 hours' },
        { minuteValue: 10080, text: 'Last 7 days' },
        { minuteValue: 43200, text: 'Last 30 days' },
      ],

      // sticky header element
      tableHeaderEl: null,
      parentElement: null,
      positionY: this.tabOptions.length ? 240 : 201,
    }
  },
  computed: {
    // get data from table first
    // until the data from server has been loaded
    defaultDetail() {
      if (this.detail) {
        return this.detail
      }

      // when still fetching the table items or when table is empty
      if (!this.items.length) {
        return null
      }

      // if the data has been found
      return this._.find(this.items, item => item.id === this.selectedId)
    },
    selectedId() {
      // when no id has been selected
      if (!this.$route.hash) {
        return undefined
      }

      return this.$route.hash.replace(/#/g, '')
    },
    // the initial a.k.a default (literally) filters
    initialFilters() {
      const filter = []

      // if tabs is enabled
      if (this.tabOptions.length) {
        // select default tab
        const defaultTab = this._.find(this.tabOptions, item => item.default)
        if (defaultTab) filter.push(defaultTab)
      }

      const retainedFilters = this.filter.filter(item => item.retain)

      if (retainedFilters.length) {
        filter.push(...retainedFilters.map(item => ({
          ...item,
          retain: undefined, // do not retain this filter for next page visit
        })))
      }

      return filter
    },
    // the initial a.k.a default (literally) filters
    initialSort() {
      return this._.find(this.sortOptions, item => item.default)
    },
    defaultSort() {
      if (!this.sortOptions.length) {
        return undefined
      }

      // find the applied sort inside the sort options (if any)
      const option = this._.find(this.sortOptions, this.sort)

      if (option) {
        return option
      }

      const columns = this.defaultFields.filter(item => item.key === this.sort.key)

      if (columns.length) {
        return {
          ...this.sort,
          label: columns[0].label,
        }
      }

      return undefined
    },
    defaultFields() {
      return [
        // always show actions column
        ...(this.hasMultipleRowActions ? [{
          key: 'select',
          label: '',
        }] : []),
        ...this.fields
          // do not show hidden column on table
          .filter(item => item.visibility === undefined
            || (item.visibility
              && (!item.visibility.length
                || item.visibility.includes('table'))))
          // add sortable value, default true
          .map(item => ({
            ...item,
            // the default is sortable except column id
            sortable: typeof item.sortable === 'undefined'
            && item.key !== 'id'
            && !['badges', 'image'].includes(item.type)
              ? false
              : item.sortable,
          })),
        // always show actions column
        {
          key: 'actions',
          label: '',
          stickyColumn: true,
        },
      ]
    },
    defaultActions() {
      if (!this.actions || !this.actions.length) {
        return ['view']
      }

      return this.actions
    },
    defaultActionCustom() {
      return this.defaultActions
        .find(item => typeof item === 'object' && item.type === 'custom')
    },
    defaultCustomActions() {
      const action = this.defaultActionCustom

      if (!action) {
        return []
      }

      return action.children
    },
    defaultActionOther() {
      return this.defaultActions
        .find(item => typeof item === 'object' && item.type === 'other')
    },
    // only actions others that has multiRowEvent callback
    defaultMultipleRowActions() {
      if (!this.defaultActionOther) {
        return null
      }

      if (!this.defaultActionOther.children.length) {
        return null
      }

      return this.defaultActionOther.children
        .filter(({ multipleRowEvent }) => multipleRowEvent)
    },
    hasActionCreate() {
      return this.defaultActions.includes('create')
    },
    hasActionDownload() {
      return this.defaultActions.includes('download')
    },
    hasActionView() {
      return this.defaultActions.includes('view')
    },
    hasActionViewOnly() {
      return this.defaultActions.includes('view')
        && this.defaultActions.length === 1
    },
    hasActionMenuViewOnly() {
      const actionMenus = this.defaultActions
        // only action inside table action are counts,
        .filter(item => item !== 'create')
        // and exclude the 'other' actions
        // need to check 'other' actions for each row individually
        .filter(item => item.type !== 'other')

      return actionMenus.includes('view')
        && actionMenus.length === 1
    },
    hasActionUpdate() {
      return this.defaultActions.includes('update')
    },
    hasActionDelete() {
      return this.defaultActions.includes('delete')
    },
    startRowNumber() {
      if (!this.pagination) {
        return undefined
      }

      return (this.pagination.currentPage * this.pagination.perPage) - this.pagination.perPage + 1
    },
    endRowNumber() {
      if (!this.pagination) {
        return undefined
      }

      // if this is the last page, show the total data instead
      if (this.pagination.totalPage === this.pagination.currentPage) {
        return this.pagination.totalData
      }

      return this.pagination.currentPage * this.pagination.perPage
    },
    defaultFilterOptions() {
      // show all fields as and options
      return this.fields
        // in the meantime, only field that has filterType
        .filter(item => item.filter)
        .map(item => ({
          key: item.key,
          label: item.label,
          filter: item.filter,
        }))
    },
    filterCollapsed() {
      const filterOptions = this.defaultFilterOptions
      const limit = this.filterCollapsedLimit

      // if the number of filters are less than the limit
      if (filterOptions.length <= limit) {
        return false
      }

      // get collapsed / hidden filters
      const collapsedFilter = filterOptions.slice(limit, filterOptions.length)
        .map(item => ({
          ...item.filter,
          key: item.filter.key ? item.filter.key : item.key, // should handle startKey & endKey
        }))

      // get collapsed / hidden active filters
      const activeCollapsedFilter = this._.intersectionBy(this.filter, collapsedFilter, 'key')

      // if collapsed toggle is true
      // or has active filter inside the collapsed filters
      return this.filterCollapsedToggle && activeCollapsedFilter.length === 0
    },
    defaultFilterOptionsCollapsible() {
      const filterOptions = this.defaultFilterOptions
      const limit = this.filterCollapsedLimit

      if (!this.filterCollapsed) {
        // show all options
        return filterOptions
      }

      return filterOptions.slice(0, limit)
    },
    hasDetail() {
      return !!(this.$listeners && this.$listeners.loadDetail)
    },
    showMultipleRowActions() {
      return this.selectedIds.length && this.hasMultipleRowActions
    },
    hasMultipleRowActions() {
      return this.defaultMultipleRowActions
        && this.defaultMultipleRowActions.length
    },
    hasFilter() {
      return this.defaultFilterOptions.length
    },
    hasToggleableField() {
      return this.$listeners && this.$listeners.changeFields
    },
    hasResetToggleableField() {
      return this.$listeners && this.$listeners.changeFields
    },
    toggleableFields() {
      return this.fields.filter(item => item.visibility !== false)
    },
    hasSelectedRow() {
      return this.selectedIds.length > 0
    },
    isIntermediateSelected() {
      const selectedIds = this._.sortBy(this.selectedIds)
      const items = this._.sortBy(this.items.map(({ id }) => id))
      const isAllSelected = this._.isEqual(selectedIds, items)

      return !!(!isAllSelected && this.selectedIds.length)
    },
  },
  watch: {
    // watch $route changes to listen url hash(#)
    $route: {
      immediate: true,
      handler(to) {
        if (to.hash) {
          // load detail from server if event listener has been set
          if (this.hasDetail) {
            this.loadDetail(this.selectedId)
          }
          this.viewDataModalVisible = true
        }
      },
    },
    viewDataModalVisible(val) {
      if (!val) {
        // remove # and reset form when the view modal closed
        this.$router.replace({ hash: undefined })
        this.$emit('resetDetail')
      }
    },
    pagination(val) {
      if (val && val.currentPage > 1 && val.totalData === 0) {
        this.$emit('paginate', {
          ...this.pagination,
          currentPage: 1,
        })
      }
    },
  },
  mounted() {
    // listen on scroll to maintain table header position
    this.listenOnScroll()

    // reset to initial(a.k.a default) filter on page change only, do not reset on page reload
    if (this.$store.state.appConfig.settings.resetTableParams === 'page'
      && this.$store.state.appConfig.route.fromPath !== this.$route.path) {
      this.resetParams()

      // TODO: temporary solution
      if (['/transaction', '/donation', '/creator-plan'].includes(this.$route.path)) {
        const filter = [
          ...this.initialFilters,
        ]

        if (!filter.filter(item => item.key === 'startedAt').length) {
          filter.push({
            key: 'startedAt',
            value: moment().subtract(43200, 'minutes').toISOString(),
            minuteValue: 43200,
            text: 'Last 30 days',
          })
        }

        this.$emit('filter', filter)
      } else if (['/transaction-v2'].includes(this.$route.path)) {
        const filter = [
          ...this.initialFilters,
        ]

        if (!filter.filter(item => item.key === 'start_date').length) {
          filter.push({
            key: 'start_date',
            value: moment().subtract(43200, 'minutes').toISOString(),
            minuteValue: 43200,
            text: 'Last 30 days',
          })
        }

        this.$emit('filter', filter)
      }
    } else {
      // the keyword's field is the only filter that its value-
      // is not always the same with what applied because of debounce.
      // So it uses local state as temporary variable and need to assign
      // the variable initial value if params is not reset.
      this.defaultKeyword = this.keyword
    }

    this.loadData()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    loadData(val) {
      this.$emit('load', val)
    },
    loadDetail(val) {
      this.$emit('loadDetail', val)
    },
    changeFilter(val) {
      // ignore when the selected filter already applied
      if (!this._.isEqual(this.filter, val)) {
        this.$emit('filter', val)
        this.loadData()
      }
    },
    selectFilter(option) {
      const index = this._.findIndex(this.filter, { key: option.key })
      // replace when the selected filter's key already applied but different/same value
      if (index !== -1) {
        const filter = [...this.filter]
        // replace
        filter.splice(index, 1, option)
        this.changeFilter(filter)
        return
      }

      // append to filter
      this.changeFilter([...this.filter, option])
    },
    toggleSelectFilter(key, option) {
      const filterOption = { key, ...option }

      const index = this._.findIndex(this.filter, { key })
      const selected = this.filter[index]
      const filter = [...this.filter]

      // if the selected value is the same with the applied one
      if (index !== -1 && option.value === selected.value) {
        // remove
        filter.splice(index, 1)
      } else if (index !== -1) {
        // replace
        filter.splice(index, 1, filterOption)
      } else {
        // append to filter
        filter.push(filterOption)
      }

      this.changeFilter(filter)
    },
    toggleAddFilter(key, option) {
      const filterOption = { key, ...option, value: [] }
      // remove when the selected filter's key already applied
      let index = this._.findIndex(this.filter, { key })
      const filter = [...this.filter]

      if (index === -1) {
        filter.push(filterOption)
        index = filter.length - 1
      }

      // have to re-create the object to detach the instance
      // from this.filter[index] instance
      const selected = { ...filter[index], value: [...filter[index].value] }
      const selectedIndex = selected.value.indexOf(option.value)

      if (selectedIndex !== -1) {
        // remove if exist
        selected.value.splice(selectedIndex, 1)
      } else {
        // append to selected filter
        selected.value.unshift(option.value)
      }
      // The filter become empty, that remove it
      if (!selected.value.length) {
        filter.splice(index, 1)
      } else {
        // re-assign because detached previously
        filter[index] = selected
      }

      this.changeFilter(filter)
    },
    toggleDatetimeFilter(startKey, endKey, option) {
      // calculate start and end values based on the selected option
      let startValue
      let endValue
      const now = moment().utcOffset(8)

      switch (option.text) {
        case 'Today':
          startValue = now.startOf('day').toISOString()
          endValue = now.endOf('day').toISOString()
          break
        case 'Yesterday':
          startValue = now.subtract(1, 'days').startOf('day').toISOString()
          endValue = now.endOf('day').toISOString()
          break
        default:
          startValue = now.subtract(option.minuteValue, 'minutes').toISOString()
          endValue = now.subtract(option.minuteValue, 'minutes').toISOString()
      }

      // create a copy of the current filter
      const filter = [...this.filter]

      // remove existing start and end filters
      const updatedFilter = filter.filter(f => f.key !== startKey && f.key !== endKey && f.key !== '')

      // prepare start filter option
      const startFilterOption = {
        key: startKey,
        value: startValue,
        ...option,
      }

      // add start filter
      updatedFilter.push(startFilterOption)

      // add end filter for Today and Yesterday
      if (option.text === 'Today' || option.text === 'Yesterday') {
        const endFilterOption = {
          key: endKey,
          value: endValue,
        }
        updatedFilter.push(endFilterOption)
      }

      // update filter
      this.changeFilter(updatedFilter)
    },
    // reset filter in specified key
    resetFilter(key) {
      // TODO: should remove, not filtering
      this.changeFilter(this.filter.filter(item => item.key !== key))
    },
    // reset filter in specified key
    resetDatetimeFilter(startKey, endKey) {
      // TODO: should remove, not filtering
      this.changeFilter(this.filter.filter(item => item.key !== startKey && item.key !== endKey))
    },
    // reset all filters
    resetAllFilter() {
      this.changeFilter(this.initialFilters)
    },
    changeSort(val) {
      // ignore when the selected sort already applied
      if (!this._.isEqual(this.defaultSort, val)) {
        this.$emit('sort', val)
        this.loadData()
      }
    },
    resetSort() {
      this.changeSort(this.initialSort)
    },
    changePagination(val) {
      // ignore when the selected pagination already applied
      if (!this._.isEqual(this.pagination, val)) {
        this.$emit('paginate', val)
        this.loadData()
      }
    },
    changePage(event, val) {
      // do not change the ui
      event.preventDefault()

      // change the ui from the pagination prop instead
      this.changePagination({
        ...this.pagination,
        currentPage: val,
      })
    },
    onChangePage: debounce(function search(val) {
      if (!val
        || val === this.pagination.currentPage // TODO: not working, don't know why
        || val > this.pagination.totalPage || val < 1) {
        return
      }

      // change the ui from the pagination prop instead
      this.changePagination({
        ...this.pagination,
        currentPage: val,
      })
    }, 300),
    changePerPage(val) {
      this.$store.commit('appConfig/UPDATE_TABLE_PER_PAGE', val)

      // reset the page to page 1
      this.$emit('paginate', {
        ...this.pagination,
        perPage: val,
        currentPage: 1,
      })

      this.loadData()
    },
    // reset all filters and sort to default
    resetParams() {
      this.$emit('filter', this.initialFilters)
      this.$emit('sort', this.initialSort)
      this.$emit('paginate', undefined)
      this.$emit('search', undefined)
    },
    onSelect(selectedRows) {
      // go to detail page if event listener loadDetail has been set
      if (this.hasDetail) {
        // go to detail route
        this.$router.push(`${this.$route.path}/${selectedRows[0].id}`)
      } else {
        // open detail popup
        this.$router.push(`${this.$route.path}#${selectedRows[0].id}`)
      }
    },
    onSort({ sortBy, sortDesc }) {
      this.changeSort({
        key: sortBy,
        direction: sortDesc ? 'desc' : 'asc',
      })
    },
    resetKeyword() {
      this.defaultKeyword = ''
      this.search('')
    },
    search: debounce(function search(val) {
      if (val) {
        this.$emit('search', val)
      } else {
        this.$emit('search', undefined)
      }

      // reset the page to page 1
      this.$emit('paginate', {
        ...this.pagination,
        currentPage: 1,
      })

      this.loadData()
    }, 750),
    // custom row class
    rowClass(item, type) {
      const classes = ['table-white-space']
      if (item && type === 'row') {
        if (item.deletedAt) {
          classes.push('row-deleted')
        }
      }

      return classes.join(' ')
    },
    // TODO: should inject hasActionCreate, etc to the field instead
    getActionOther(data) {
      let actionOther = this.defaultActionOther

      if (!actionOther) {
        return null
      }

      actionOther = {
        ...actionOther,
        children: actionOther.children
          .filter(item => item.visibility === undefined
            || item.visibility === true
            || (typeof item.visibility === 'object'
              && item.visibility.callback
              && item.visibility.callback(data))),
      }

      if (!actionOther.children.length) {
        return null
      }

      return actionOther.children
    },
    getHasActionOther(data) {
      const actionOther = this.getActionOther(data)
      return actionOther && actionOther.length
    },
    getFilterIsActive(item) {
      if (item.filter.type === 'datetime') {
        return this._.some(this.filter, { key: item.filter.startKey })
      }

      if (item.filter.type === 'select') {
        return this._.some(this.filter, { key: item.filter.key ? item.filter.key : item.key })
      }

      if (item.filter.type === 'multiple') {
        return this._.some(this.filter, { key: item.filter.key ? item.filter.key : item.key })
      }

      if (item.filter.type === 'profile') {
        return this._.some(this.filter, { key: item.filter.key ? item.filter.key : item.key })
      }

      return false
    },
    toggleFieldVisibility(item) {
      const index = this._.findIndex(this.fields, { key: item.key })
      if (index === -1) {
        return
      }

      // change field visibility
      const fields = [...this.fields]
      const field = { ...item }

      if (item.visibility === undefined) {
        // if visible in all places (default)
        // make it visible only on detail popup/page
        field.visibility = ['view']
      } else if (item.visibility && !item.visibility.length) {
        // if the visibility option defined (might be in array)
        // and the array is empty
        // make it visible only on detail popup/page
        field.visibility.push('view')
      } else if (item.visibility && item.visibility.includes('table')) {
        // if the visibility option defined (might be in array)
        // and the array is includes table
        // make it visible only on detail popup/page
        field.visibility = field.visibility.filter(place => place !== 'table')
      } else if (item.visibility && item.visibility.length >= 1 && !item.visibility.includes('table')) {
        // if the field is invisible
        // set to undefined to reset to default
        field.visibility = undefined
      }

      fields.splice(index, 1, field)
      this.$emit('changeFields', fields)
    },
    resetToggleableField() {
      this.$emit('resetFields')
    },
    openDatePicker(startKey, endKey) {
      this.datePickerModalVisible = true
      this.dateRangeStartKey = startKey
      this.dateRangeEndKey = endKey
    },
    onDatePickerModalHidden() {
      this.datePickerModalVisible = false

      // reset value
      this.dateRange = null
      this.dateRangeStartKey = ''
      this.dateRangeEndKey = ''
    },
    selectDateRange() {
      if (!this.dateRange) {
        return
      }

      const dates = this.dateRange.split(' to ')
      const filter = [...this.filter]

      const startIndex = this._.findIndex(filter, { key: this.dateRangeStartKey })
      // if the selected filter is already applied
      if (startIndex !== -1) {
        // remove
        filter.splice(startIndex, 1)
      }

      const endIndex = this._.findIndex(filter, { key: this.dateRangeEndKey })
      // if the selected filter is already applied
      if (endIndex !== -1) {
        // remove
        filter.splice(endIndex, 1)
      }

      const startFilterOption = {
        key: this.dateRangeStartKey,
        value: moment(dates[0])
          .startOf('day')
          .utc()
          .toISOString(),
        text: dates.map(item => moment(item).format('MMM D')).join(' - '),
      }

      filter.push(startFilterOption)

      const isOneDay = dates.length === 1
      const endFilterOption = {
        key: this.dateRangeEndKey,
        value: moment(dates[isOneDay ? 0 : 1])
          .endOf('day')
          .utc()
          .toISOString(),
      }

      filter.push(endFilterOption)

      this.changeFilter(filter)
    },
    listenOnScroll() {
      [this.tableHeaderEl] = document.getElementsByClassName('fixed-thead')
      this.parentElement = this.tableHeaderEl.parentElement

      window.addEventListener('scroll', this.onScroll)
    },
    onScroll() {
      const coordinates = this.parentElement.getBoundingClientRect()
      let offset = 0
      offset += !this.hasToggleableField ? -43 : 0

      if (coordinates.y < this.positionY) {
        this.tableHeaderEl.style.transform = `translate(0,${-coordinates.y + this.positionY + offset}px)`
      } else {
        this.tableHeaderEl.style.transform = 'translate(0,0)'
      }
    },
    changeSelectedRow(val) {
      this.$emit('select', val)
    },
    toggleSelectRow(id) {
      const { selectedIds } = this

      if (selectedIds.includes(id)) {
        const index = selectedIds.indexOf(id)
        selectedIds.splice(index, 1)
        this.changeSelectedRow(selectedIds)
      } else {
        selectedIds.push(id)
        this.changeSelectedRow(selectedIds)
      }
    },
    toggleSelectAllRow() {
      if (this.selectedIds.length) {
        this.resetSelectAllRow()
      } else {
        this.changeSelectedRow(this.items.map(({ id }) => id))
      }
    },
    resetSelectAllRow() {
      this.changeSelectedRow([])
    },
    requestExport() {
      // find one datetime filter, if any
      const dateRangeFilter = this.defaultFilterOptions.find(({ filter }) => filter.type === 'datetime')

      const appliedDateRangeFilter = dateRangeFilter
        ? this.filter.find(({ key }) => key === dateRangeFilter.filter.startKey)
        : null

      // when no datetime filter applied, show alert
      if (dateRangeFilter && !appliedDateRangeFilter) {
        this.alertExportModalVisible = true

        this.resetAlertExportCountDown()
        this.countDownAllowExport()
        return
      }

      this.confirmExportModalVisible = true
    },
    countDownAllowExport() {
      setTimeout(() => {
        this.exportWithoutDateRangeCountDown -= 1 // decrement

        if (this.exportWithoutDateRangeCountDown > 0) {
          this.countDownAllowExport()
        }
      }, 1000)
    },
    resetAlertExportCountDown() {
      this.exportWithoutDateRangeCountDown = 3 // n seconds
    },
    onAlertExportModalHidden() {
      this.alertExportModalVisible = false
      this.confirmExportModalVisible = false
      this.resetAlertExportCountDown()
    },
    exportData() {
      this.$emit('download')
    },
    searchProfile: debounce(function search(val, item) {
      this.$store.dispatch(item.filter.actionSearch, val)
    }, 500),
    openProfileDropdown(item) {
      // Only load initial options if it's not loaded yet and not in filter
      if (item.filter.loadInitialOptions && !this._.find(this.filter, { key: item.filter.key ? item.filter.key : item.key })) {
        this.$store.dispatch(item.filter.actionSearch, '')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
